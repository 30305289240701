import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Idea, Target, Trusted } from '../assets/images';
import Heading from "./heading";

const CoreValuesSection: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
      <section id="values" className="py-16 bg-transparent">
        <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
          <Heading>Our Commitment to You</Heading>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center" data-aos="fade-right">
              <img src={Idea} alt="Innovation" className="w-16 h-16 mb-4" />
              <h3 style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }} className="text-xl font-bold">Innovation</h3>
              <p className="text-[#4A5568] mt-2">
                We believe in pushing the boundaries of technology, continuously innovating to deliver cutting-edge solutions to our clients.
              </p>
            </div>
            <div className="flex flex-col items-center" data-aos="zoom-in" data-aos-delay="200">
              <img src={Trusted} alt="Trust" className="w-16 h-16 mb-4" />
              <h3 style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }} className="text-xl font-bold ">Trust</h3>
              <p className="text-[#4A5568] mt-2">
                Building long-lasting relationships through honesty and transparency, we earn the trust of our clients by delivering on our promises.
              </p>
            </div>
            <div className="flex flex-col items-center" data-aos="fade-left" data-aos-delay="400">
              <img src={Target} alt="Future-Focused" className="w-16 h-16 mb-4" />
              <h3 style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }} className="text-xl font-bold ">Quality</h3>
              <p className="text-[#4A5568] mt-2">
                We are dedicated to delivering the highest quality in everything we do, ensuring our solutions exceed your expectations.
              </p>
            </div>
          </div>
        </div>
      </section>
  );
};

export default CoreValuesSection;
