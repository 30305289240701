import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Heading from "./heading";
import { Cindy, CindyNicole, Jared, Mikhail, Nia, Patric } from "../assets/images";

const TeamCollageSection: React.FC = () => {
    useEffect(() => {
        AOS.init({ duration: 500 });
    }, []);

    return (
        <section id="team" className="bg-transparent pt-6 ">
            <div className="max-w-7xl mx-auto px-6 md:px-12">
                <Heading>Meet Our Team</Heading>
                <div className="grid grid-cols-3 md:grid-cols-6 gap-2 py-8 w-full" data-aos="fade-up">
                    <img src={CindyNicole} alt="Cindy Nicole" className="w-full h-full object-cover"/>
                    <img src={Cindy} alt="Cindy" className="w-full h-full object-cover"/>
                    <img src={Mikhail} alt="Mikhail" className="w-full h-full object-cover"/>
                    <img src={Patric} alt="Patric" className="w-full h-full object-cover"/>
                    <img src={Nia} alt="Nia" className="w-full h-full object-cover"/>
                    <img src={Jared} alt="Jared" className="w-full h-full object-cover"/>

                </div>
            </div>
        </section>
    );
};

export default TeamCollageSection;

