import { json } from 'd3-fetch';
import { geoOrthographic, geoPath } from 'd3-geo';
import { feature, mesh } from 'topojson-client';
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Heading from "./heading";

interface WorldData {
    land: any;
    borders: any;
}

interface Location {
    name: string;
    coordinates: [number, number];
    projects?: number;
}

const WorldGlobe = () => {
    const [data, setData] = useState<WorldData | null>(null);
    const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);
    const [tooltipPos, setTooltipPos] = useState<{ x: number; y: number } | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const lastMousePosition = useRef({ x: 0, y: 0 });
    const rotation = useRef<[number, number]>([-12, -8]);

    const locations: Location[] = [
        { name: 'Nelspruit', coordinates: [30.9703, -25.4745], projects: 5 },
        { name: 'Gauteng', coordinates: [28.0473, -26.2041], projects: 8 },
        { name: 'London', coordinates: [-0.1278, 51.5074], projects: 12 }
    ];

    useEffect(() => {
        json('https://unpkg.com/world-atlas@2.0.2/countries-110m.json').then((topoData: any) => {
            const { countries, land } = topoData.objects;
            setData({
                land: feature(topoData, land),
                borders: mesh(topoData, countries, (a: any, b: any) => a !== b)
            });
        });
    }, []);

    const render = () => {
        if (!data || !canvasRef.current) return;

        const context = canvasRef.current.getContext('2d');
        if (!context) return;

        const dpr = window.devicePixelRatio || 1;
        const width = canvasRef.current.clientWidth;
        const height = (width / 4) * 3;
        canvasRef.current.width = width * dpr;
        canvasRef.current.height = height * dpr;
        context.scale(dpr, dpr);
        context.clearRect(0, 0, width, height);
        context.imageSmoothingEnabled = true;

        const projection = geoOrthographic()
            .scale(width / 3.2)
            .translate([width / 2, height / 2])
            .rotate(rotation.current);

        const pathGenerator = geoPath(projection, context);

        const createLandGradient = () => {
            const gradient = context.createLinearGradient(0, 0, 0, height / 2);
            gradient.addColorStop(0, '#0088D4');
            gradient.addColorStop(1, '#002C6E');
            return gradient;
        };
        const landGradient = createLandGradient();

        context.fillStyle = '#555';
        context.beginPath();
        context.ellipse(width / 2, height * 0.9, width * 0.2, height * 0.03, 0, 0, 2 * Math.PI);
        context.fill();

        context.fillStyle = '#333';
        context.beginPath();
        context.ellipse(width / 2, height * 0.87, width * 0.1, height * 0.015, 0, 0, 2 * Math.PI);
        context.fill();

        context.beginPath();
        pathGenerator({ type: 'Sphere' });
        context.fillStyle = '#e0f7ff';
        context.fill();

        context.beginPath();
        pathGenerator(data.land);
        context.fillStyle = landGradient;
        context.fill();

        context.beginPath();
        pathGenerator(data.borders);
        context.strokeStyle = '#ffffff';
        context.lineWidth = 0.5;
        context.stroke();

        locations.forEach(location => {
            const [longitude] = location.coordinates;
            const [rotLongitude] = rotation.current;

            const adjustedLongitude = (longitude - rotLongitude + 180 + 360) % 360 - 180;
            const isVisible = Math.abs(adjustedLongitude) <= 90;

            if (!isVisible) return;

            const projected = projection(location.coordinates);
            if (projected && projected[0] >= 0 && projected[0] <= width && projected[1] >= 0 && projected[1] <= height) {
                const [x, y] = projected;
                const lineHeight = 40;
                const centerX = width / 2;
                const centerY = height / 2;
                const angle = Math.atan2(y - centerY, x - centerX);
                const endX = x + lineHeight * Math.cos(angle);
                const endY = y + lineHeight * Math.sin(angle);

                const lineGradient = context.createLinearGradient(x, y, endX, endY);
                lineGradient.addColorStop(0, 'rgba(72, 201, 176, 1)');
                lineGradient.addColorStop(1, 'rgba(72, 201, 176, 0)');

                context.beginPath();
                context.moveTo(x, y);
                context.lineTo(endX, endY);
                context.strokeStyle = lineGradient;
                context.lineWidth = 3;
                context.shadowColor = 'rgba(72, 201, 176, 0.8)';
                context.shadowBlur = 15;
                context.stroke();

                context.shadowBlur = 0;
                context.beginPath();
                context.arc(x, y, 4, 0, 2 * Math.PI);
                context.fillStyle = 'rgba(72, 201, 176, 1)';
                context.fill();
            }
        });
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        if (!canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const dpr = window.devicePixelRatio || 1;
        const offsetX = (event.clientX - rect.left) * dpr;
        const offsetY = (event.clientY - rect.top) * dpr;

        if (isDragging) {
            const deltaX = event.clientX - lastMousePosition.current.x;
            lastMousePosition.current = { x: event.clientX, y: event.clientY };

            rotation.current = [
                rotation.current[0] + deltaX * 0.3,
                rotation.current[1]
            ];
            requestAnimationFrame(render);
        }
    };

    const handleTouchMove = (event: React.TouchEvent) => {
        if (!canvasRef.current || event.touches.length !== 1) return;

        const touch = event.touches[0];
        const rect = canvasRef.current.getBoundingClientRect();
        const dpr = window.devicePixelRatio || 1;
        const offsetX = (touch.clientX - rect.left) * dpr;

        if (isDragging) {
            const deltaX = offsetX - lastMousePosition.current.x;
            lastMousePosition.current = { x: offsetX, y: 0 };

            rotation.current = [
                rotation.current[0] + deltaX * 0.3,
                rotation.current[1]
            ];
            requestAnimationFrame(render);
        }
    };

    const handleMouseDown = (event: React.MouseEvent) => {
        setIsDragging(true);
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        setIsDragging(true);
        const touch = event.touches[0];
        const rect = canvasRef.current?.getBoundingClientRect();
        if (rect) {
            lastMousePosition.current = {
                x: (touch.clientX - rect.left) * (window.devicePixelRatio || 1),
                y: 0
            };
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        render();
    }, [data, hoveredLocation]);

    return (
        <>
            <Heading>Internationally</Heading>
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, ease: 'easeOut' }}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
            >
                <div className="canvas-container">
                    <canvas
                        ref={canvasRef}
                        style={{
                            cursor: isDragging ? 'grabbing' : 'grab',
                            width: '100%',
                            height: 'auto'
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    />
                    {hoveredLocation && tooltipPos && (
                        <div
                            style={{
                                position: 'absolute',
                                left: tooltipPos.x,
                                top: tooltipPos.y,
                                padding: '8px',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                color: '#fff',
                                borderRadius: '4px',
                                pointerEvents: 'none',
                                zIndex: 1000,
                            }}
                        >
                            {hoveredLocation.name} <br />
                            Projects: {hoveredLocation.projects}
                        </div>
                    )}
                </div>
            </motion.div>
            <style>{`
                .canvas-container {
                    width: 100%;
                    max-width: 600px;
                    aspect-ratio: 4 / 3;
                }
                @media (max-width: 768px) {
                    .canvas-container {
                        max-width: 100%;
                    }
                }
            `}</style>
        </>
    );
};

export default WorldGlobe;
