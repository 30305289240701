import React from 'react';
import 'aos/dist/aos.css';
import Heading from "./heading";

interface Review {
    name: string;
    title: string;
    picture: string;
    reviewText: string;
    linkedInUrl: string;
}

interface ReviewSectionProps {
    reviews: Review[];
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ reviews }) => {
    return (
        <section id="reviews" className="bg-transparent py-12">
            <div className="max-w-7xl mx-auto px-6 md:px-12">
                <Heading>What our clients say</Heading>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {reviews.map((review, index) => (
                        <div key={index} className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-lg" data-aos="fade-up">
                            <img src={review.picture} alt={review.name} className="w-24 h-24 object-cover rounded-full mb-4" />
                            <div className="flex items-center space-x-2">
                                <h3 className="text-lg font-semibold">{review.name}</h3>
                                <a href={review.linkedInUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                                        <path
                                            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.804-1.75-1.732s.784-1.732 1.75-1.732 1.75.804 1.75 1.732-.784 1.732-1.75 1.732zm13.5 11.268h-3v-5.411c0-1.291-.025-2.95-1.8-2.95-1.8 0-2.075 1.404-2.075 2.854v5.507h-3v-10h2.875v1.367h.041c.4-.758 1.375-1.55 2.833-1.55 3.025 0 3.583 1.991 3.583 4.579v5.604z"/>
                                    </svg>
                                </a>
                            </div>
                            <p className="text-sm text-gray-500">{review.title}</p>
                            <p className="mt-4 text-gray-700">{review.reviewText}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ReviewSection;
