import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import RoutesComponent from './Routes/Routes';
import Hotjar from '@hotjar/browser';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

function App() {
    const siteId = 5149144;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);

    useEffect(() => {
        // Initialize Google Tag Manager
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=AW-16672155988`;
        document.head.appendChild(script);

        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16672155988');
        `;
        document.head.appendChild(inlineScript);
    }, []);

    const queryClient = new QueryClient();


    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <RoutesComponent />
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
