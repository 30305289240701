import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Cloud, CodeAudit, SoftwareDevelopment } from '../assets/images';
import Heading from "./heading";

const ServicesSection: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
      <section id="services" className="bg-transparent py-12">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          <Heading
          >
            Solutions Designed to Elevate
          </Heading>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
            <div className="flex flex-col items-center" data-aos="fade-up">
              <img
                  src={SoftwareDevelopment}
                  alt="Service 1"
                  className="w-full h-60 object-cover rounded-lg mb-4"
              />
              <h3  style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }} className="text-xl font-bold ">App Development</h3>
              <p className="text-[#4A5568] mt-2 text-center">
                Empower your business with custom, user-friendly mobile and web applications. Our solutions are designed to offer smooth, intuitive experiences that engage your customers across all platforms.
              </p>
              {/*<button*/}
              {/*    className="mt-4 px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 transition duration-300"*/}
              {/*>*/}
              {/*  Start Building*/}
              {/*</button>*/}
            </div>

            <div className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
              <img
                  src={Cloud}
                  alt="Service 2"
                  className="w-full h-60 object-cover rounded-lg mb-4"
              />
              <h3  style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }}  className="text-xl font-bold">Cloud Consulting</h3>
              <p className="text-[#4A5568] mt-2 text-center">
                Transform your business with expert cloud consulting tailored to your needs. We help you optimize your cloud infrastructure, enhancing scalability and efficiency.
              </p>
              {/*<button*/}
              {/*    className="mt-4 px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 transition duration-300"*/}
              {/*>*/}
              {/*  Elevate Your Cloud*/}
              {/*</button>*/}
            </div>

            <div className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
              <img
                  src={CodeAudit}
                  alt="Service 3"
                  className="w-full h-60 object-cover rounded-lg mb-4"
              />
              <h3  style={{
                background: 'linear-gradient(90deg, #0088D4 0%, #002C6E 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block',
              }}  className="text-xl font-bold ">Software Audit</h3>
              <p className="text-[#4A5568] mt-2 text-center">
                Secure your code and improve its performance with our thorough code audit. We identify vulnerabilities, inefficiencies, and potential risks, providing actionable recommendations.              </p>
              {/*<button*/}
              {/*    className="mt-4 px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 transition duration-300"*/}
              {/*>*/}
              {/*  Secure Your Code*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </section>
  );
};

export default ServicesSection;
