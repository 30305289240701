import React from "react";
import ServicesSection from "../Components/our-services";
import CoreValuesSection from "../Components/core-values";
import ContactInformation from "../Components/contact-us";
import HeroSection from "../Components/hero-section";
import Navbar from "../Components/Navbar";
import ClientsSection from "../Components/our-clients";
import SpinningElement from "../Components/spinner";
import GoogleReviewsBadge from "../Components/google-reviews";
import WorldGlobe from "../Components/world-map";
import SoftwareDevelopmentFlow from "../Components/our-process";
import PortfolioSection from "../Components/portfolio-section";
import TeamCollageSection from "../Components/team-collage";
import {CindyNicole, Jean, Jonathan, Patric} from "../assets/images";
import ReviewSection from "../Components/reviews";

function Home() {

    const reviews = [
        {
            name: 'Jonathan Coster',
            title: 'Senior Cloud Infrastructure Consultant at Netsurit',
            picture: Jonathan,
            reviewText: 'Cirrus Bridge provided exceptional support and professional services throughout the implementation of our complex cloud systems. It was a pleasure working with their team of knowledgeable experts. Their professionalism and expertise were invaluable in ensuring a successful project.',
            linkedInUrl: 'https://www.linkedin.com/in/jonathancoster/'
        },
        {
            name: 'Jean Matthee',
            title: 'Head of Product at HeadsUp',
            picture: Jean,
            reviewText: 'Working with the Cirrus Bridge team has been an absolute pleasure.',
            linkedInUrl: 'https://www.linkedin.com/in/jean-matthee-1a215b95/'
        }
    ];

    return (
        <div className="background" style={{
            overflowX: 'hidden',
            position: 'relative',
        }}>
            <Navbar/>
            <HeroSection/>
            <ServicesSection/>
            {/*<SoftwareDevelopmentFlow></SoftwareDevelopmentFlow>*/}
            <ClientsSection/>

            <WorldGlobe></WorldGlobe>
            {/*<PortfolioSection/>*/}
            <ReviewSection reviews={reviews} />
            <TeamCollageSection/>
            <CoreValuesSection/>
            <ContactInformation/>
        </div>//unpkg.com/three-globe/example/img/earth-topology.png
    );
}

export default Home;
