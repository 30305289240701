import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-t-2 border-blue-900 py-4">
      <div className="text-center text-gray-600">
        © 2024 Cirrus Bridge. All rights reserved
      </div>
    </footer>
  );
};

export default Footer;