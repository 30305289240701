import React from 'react';
import { Container, Box, Grid, Card, CardMedia, CardActionArea, useMediaQuery, useTheme } from '@mui/material';
import Marquee from 'react-fast-marquee';
import { Codehesion, Discon, HodlOtc, SmokeCI, Tipsi } from '../assets/images';
import Heading from "./heading";

const ClientsSection: React.FC = () => {
    const clientLogos = [
        { src: Codehesion, alt: "Codehesion", link: "" },
        { src: SmokeCI, alt: "SmokeCI", link: "https://www.smokeci.com" },
        { src: HodlOtc, alt: "Hodl Otc", link: "https://hodlotc.com/" },
        // { src: Tipsi, alt: "Tipsi", link: "https://hodlotc.com/" },
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const useMarquee = clientLogos.length > 8;

    return (
        <section
            id="clients"
            style={{
                padding: isSmallScreen ? '60px 0' : '80px 0', // Ensures consistent vertical spacing on both mobile and desktop
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundColor: 'transparent'
            }}
        >
            <Container maxWidth="lg" sx={{ px: isSmallScreen ? 2 : 4 }}>
                    <Heading>
                        Trusted by Leading Brands
                    </Heading>


                <div style={{ overflow: 'hidden' }}>
                    {useMarquee ? (
                        <Marquee gradient={false} speed={30} pauseOnHover>
                            {clientLogos.map((logo, index) => (
                                <Card
                                    key={index}
                                    style={{
                                        margin: isSmallScreen ? '0 15px' : '0 20px', // Provides enough spacing around each card
                                        maxWidth: 150,
                                        padding: 10,
                                        textAlign: 'center',
                                        backgroundColor: '#fff',
                                        boxShadow: theme.shadows[5],
                                        borderRadius: '10px',
                                        transition: 'transform 0.4s ease, box-shadow 0.4s ease',
                                    }}
                                    className="marquee-card"
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = 'scale(1.1)';
                                        e.currentTarget.style.boxShadow = theme.shadows[10];
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = 'scale(1)';
                                        e.currentTarget.style.boxShadow = theme.shadows[5];
                                    }}
                                >
                                    <CardActionArea href={logo.link} target="_blank" rel="noopener noreferrer">
                                        <CardMedia
                                            component="img"
                                            alt={logo.alt}
                                            image={logo.src}
                                            style={{ width: '100%', objectFit: 'contain', height: 100 }}
                                        />
                                    </CardActionArea>
                                </Card>
                            ))}
                        </Marquee>
                    ) : (
                        <Grid container spacing={isSmallScreen ? 2 : 4} justifyContent="center">
                            {clientLogos.map((logo, index) => (
                                <Grid item xs={6} sm={4} md={3} key={index}>
                                    <Card
                                        style={{
                                            backgroundColor: '#fff',
                                            textAlign: 'center',
                                            padding: 20,
                                            boxShadow: theme.shadows[5],
                                            borderRadius: '10px',
                                            transition: 'transform 0.4s ease, box-shadow 0.4s ease',
                                            margin: '10px auto', // Ensures consistent spacing around each card
                                        }}
                                        className="client-card"
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.transform = 'scale(1.1)';
                                            e.currentTarget.style.boxShadow = theme.shadows[10];
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.transform = 'scale(1)';
                                            e.currentTarget.style.boxShadow = theme.shadows[5];
                                        }}
                                    >
                                        <CardActionArea href={logo.link} target="_blank" rel="noopener noreferrer">
                                            <CardMedia
                                                component="img"
                                                alt={logo.alt}
                                                image={logo.src}
                                                style={{ width: '100%', objectFit: 'contain', height: 100 }}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </div>
            </Container>
        </section>
    );
};

export default ClientsSection;
